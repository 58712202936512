import { initializeApp } from 'firebase/app'
import 'firebase/firestore'
import { getFirestore, collection, getDocs, updateDoc, serverTimestamp } from 'firebase/firestore';
// Get a Firestore instance
import { doc, setDoc, addDoc, getDoc, deleteDoc, onSnapshot } from "firebase/firestore";

import { getMessaging, getToken, onMessage } from "firebase/messaging";

let app = null;

let messagingApp = null;




let db = null;
function getApp(){
  if(!app){
    app = initializeApp({
        apiKey: "AIzaSyCqfD2JRVgEsBgy_cqTYDdVvQM_4IorPWQ",
        authDomain: "app-nubempresa.firebaseapp.com",
        projectId: "app-nubempresa",
        storageBucket: "app-nubempresa.firebasestorage.app",
        messagingSenderId: "114981258126",
        appId: "1:114981258126:web:50efea3f40a60f2e7f9adf"
    
    })
  }
  return app;
}

function getDb(){
  if(!db){
    let app = getApp();
    db = getFirestore(app);
    orderChangesCollection = collection(db, 'order-changes');
    storeEventsCollection = collection(db, 'order-events');
  }
  console.log('getDb ....', db)
  return db;
}

function getMessagingApp(){
  if(!messagingApp){
    try {
      let app = getApp();
      messagingApp = getMessaging(app);
      // Tu código para trabajar con Firebase Messaging aquí
    } catch (error) {
      console.error('Error al inicializar Firebase Messaging:', error);
      // Manejo del error cuando el navegador no es compatible
      alert('Lo sentimos, tu navegador no es compatible con la funcionalidad de mensajería de Firebase. Por favor, utiliza un navegador diferente.');
    
    }
  }
}

export const messaging = messagingApp;

let mcId = localStorage.getItem("mcId");
if (!mcId || mcId == null || mcId == undefined || mcId == "") {
  mcId = Math.floor(Math.random() * 100) + 1
  localStorage.setItem("mcId", mcId);
}

export const machineId = mcId;

export async function getCities() {

  /*const alovelaceDocumentRef = doc(db, 'cities', '222');
  setDoc(alovelaceDocumentRef, {name: 'Bogota', capital: true }, { merge: true });
  console.log(alovelaceDocumentRef)*/

  /* const docRef  = doc(db, 'cities', '222');
   const updateTimestamp = await updateDoc(docRef, {
       timestamp: serverTimestamp()
   });*/
  let db = getDb();
  if(db){
    const citiesCol = collection(db, 'cities');
    const citySnapshot = await getDocs(citiesCol);
    const cityList = citySnapshot.docs.map(doc => doc.data());
    return cityList;
  }
  return null;
}

export function ping(id) {
  let db = getDb();
  if(db){
    const storeRef = doc(db, 'stores', getIdStore(id));
    updateDoc(storeRef, {
      ping: serverTimestamp(),
      mcId: machineId
    });
    // Actualizar el heartbeat cada minuto (ajusta según sea necesario)
    setInterval(() => {
      //console.log("ping", id)
      updateDoc(storeRef, {
        ping: serverTimestamp(),
        mcId: machineId
      });
    }, 60 * 1000);
  }
}

function getIdStore(id) {
  id = "sub-" + id;
  //return id.replace(/-/g, "");
  return id;
}

//let machineCtrol = null;
export async function existsStore(id) {
  let db = getDb();
  if(db){
    const storeRef = doc(db, 'stores', getIdStore(id));
    const storeSnap = await getDoc(storeRef);
    //console.log(storeSnap.data())
    if (storeSnap.exists()) {
      //let data = storeSnap.data();
      //machineCtrol = parseInt(data.mcId)
      return true;
    } else {

      return false;
    }
  }
  return false;
}

export async function createStore(id) {
  let db = getDb();
  if(db){
    const storeRef = doc(db, 'stores', getIdStore(id));
    const document = {
      st: "online",
      ping: serverTimestamp(),
      //mcId: machineId
    };
    setDoc(storeRef, document)
      .then(() => {
        console.log('Documento creado con éxito.');
      })
      .catch((error) => {
        console.error('Error al crear el documento:', error);
      });
    }else{
      console.error('Error al crear el documento:', error);
    }
}

let storeEventsCollection = null;

export async function addEvent(storeId, event) {
  let db = getDb();
  if(db){
    const eventsRef = collection(storeEventsCollection, 'sub-' + storeId, 'events');
    try {
      // Agrega un nuevo documento a la subcolección 'eventos'
      event['time'] = serverTimestamp();
      await addDoc(eventsRef, event);
      console.log('Evento agregado con éxito.');
    } catch (error) {
      console.error('Error al agregar el evento:', error);
    }
  }
}

// Función para eliminar un evento de un restaurante
export async function deleteEvent(storeId, eventId) {
  let db = getDb();
  if(db){
    const eventoRef = doc(storeEventsCollection, 'sub-' + storeId, 'events', eventId);
    try {
      // Elimina el documento de la subcolección 'eventos'
      await deleteDoc(eventoRef);
      console.log('Evento eliminado con éxito.');
    } catch (error) {
      console.error('Error al eliminar el evento:', error);
    }
  }
}

/*
export function listenEvents(storeId, fncCreated, fncRemoved) {
  const eventosRef = collection(storeEventsCollection, storeId, 'events');
  
  // Crear una consulta para obtener solo los cambios desde ahora
  const eventosQuery = query(eventosRef, where('time', '>', new Date()));

  const unsubscribe = onSnapshot(eventosQuery, { includeMetadataChanges: false }, (snapshot) => {
    snapshot.docChanges().forEach((change) => {
      if (change.type === 'added') {
        if (typeof fncCreated === 'function') {
          fncCreated(change.doc.data());
        }
      }
      if (change.type === 'removed') {
        if (typeof fncRemoved === 'function') {
          fncRemoved(change.doc.data());
        }
      }
    });
  });

  return unsubscribe;
}*/

export async function listenEvents(storeId, fncCreated, fncRemoved) {
  let db = getDb();
  if(db){
    const eventosRef = collection(storeEventsCollection, 'sub-' + storeId, 'events');


    const unsubscribe = onSnapshot(eventosRef, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === 'added') {
          if (typeof fncCreated === "function") {
            fncCreated(change.doc.data(), change.doc.id);
          }
        }
        if (change.type === 'removed') {
          if (typeof fncRemoved === "function") {
            fncRemoved(change.doc.data());
          }
        }
      });
    });

    return unsubscribe;
  }else{
    return null;
  }
}

let orderChangesCollection = null;

 

export async function addChangeStatus(storeId, event) {
  let db = getDb();
  if(db){
    const eventsRef = collection(orderChangesCollection, 'sub-' + storeId, 'events');
    try {
      // Agrega un nuevo documento a la subcolección 'eventos'
      event['time'] = serverTimestamp();
      await addDoc(eventsRef, event);
      console.log('Evento agregado con éxito.');
    } catch (error) {
      console.error('Error al agregar el evento:', error);
    }
  }else{
    console.error('Error al agregar el evento:', error);
  }
}

// Función para eliminar un evento de un restaurante
export async function deleteOrderChange(storeId, eventId) {
  let db = getDb();
  if(db){
    const eventoRef = doc(orderChangesCollection, 'sub-' + storeId, 'events', eventId);
    try {
      // Elimina el documento de la subcolección 'eventos'
      await deleteDoc(eventoRef);
    } catch (error) {
      console.error('Error al eliminar el evento:', error);
    }
  }
}


export async function listenOrderChanges(storeId, fncCreated, fncRemoved) {
  let db = getDb();
  if(db){
    const orderChangesRef = collection(orderChangesCollection, 'sub-' + storeId, 'events');

    const unsubscribe = onSnapshot(orderChangesRef, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === 'added') {
          if (typeof fncCreated === "function") {
            fncCreated(change.doc.data(), change.doc.id);
          }
        }
        if (change.type === 'removed') {
          if (typeof fncRemoved === "function") {
            fncRemoved(change.doc.data());
          }
        }
      });
    });

    return unsubscribe;
  }else{
    return null;
  }
}

/*.onSnapshot((doc) => {
    console.log("Current data: ", doc.data());
});*/
/*collection(db, 'cities').doc(id)
    .onSnapshot((doc) => {
        console.log("Current data: ", doc.data());
    });*/
export async function isStoreActive(id, mins) {
  let db = getDb();
  if(db){
    const storeRef = doc(db, 'stores', 'sub-' + id);
    const storeSnapshot = await getDoc(storeRef);
    let docItem = null;
    if (storeSnapshot.exists()) {
      docItem = storeSnapshot.data();
    }
    if (docItem) {
      let localtTime = (new Date()).getTime();
      const marcaDeTiempoServidor = docItem.ping;
      const serverDate = marcaDeTiempoServidor.toDate();
      const serverTimestamp = serverDate.getTime();
      let diffInMiliseconds = Math.abs(serverTimestamp - localtTime);
      //console.log(serverDate, serverTimestamp)
      //console.log(new Date(),(new Date()).getTime())
      // console.log("diffInMiliseconds", diffInMiliseconds,  (61 * 1000))
      return (diffInMiliseconds <= (61 * 1000));
    }
  }
  return false;

}

export function requestPermission() {
  console.log('Requesting permission...');
  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      console.log('Notification permission granted.')
    }
  })
}

export function setTokenFcm(http) {
  if (messaging) {
    getToken(messaging, { vapidKey: process.env.VAPID_KEY }).then((currentToken) => {
      if (currentToken) {
        http.post('api/v1/set-token', { fcm_token: currentToken }).then((res) => {
          console.log('token asignado');
        })
      } else {
        requestPermission();
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });

    onMessage(messaging, function ({ notification, fcmOptions }) {
      //console.log(notification);
      //console.log(fcmOptions);
      const message = new Notification(notification.title, {
        body: notification.body,
        image: notification.image ?? null,
        // actions: {
        //   action: fcmOptions.link,
        //   title: 'redirigir'
        // }
      });
      message.onclick = (event) => {
        if (fcmOptions && fcmOptions.link) {
          event.preventDefault();
          window.open(fcmOptions.link, "_blank");
        }
      }
    });
  }
}

